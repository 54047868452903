import React, { Component } from 'react'
import SignaturePad from "signature_pad";
import { Button } from '@material-ui/core';
import SavedSignature from "./SavedSignature"



var dataURLtoBlob = require('dataurl-to-blob');

const divstyle = {
 
    boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
    marginBottom :"10px",
    border : "1px solid #000"

}
const btndivstyle ={
  display:'flex',
  justifyContent: "space-between",
  alignItems:"center"
}



class SignPad extends React.Component {
  state = {
    canvas: "",
    signpad : "",
    openPopUp: false,
    opensncdPopup:false,
    basestring: ""
  }
    constructor(props){
      super(props);

      this.saveasJpeg = this.saveasJpeg.bind(this);
      this.saveasPng = this.saveasPng.bind(this);
      this.startDraw = this.startDraw.bind(this);
      this.startErase = this.startErase.bind(this);
      this.clearCanvas = this.clearCanvas.bind(this);

      this.canvas = React.createRef();

    }

componentDidMount(){
  
 let signaturePad = new SignaturePad(this.canvas.current, {
    backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
  });
  this.setState({
    canvas: this.canvas.current,
    signpad : signaturePad
  })
  
 
}





saveasJpeg = () => {
  if (this.state.signpad.isEmpty()) {
    return alert("Please provide a signature first.");
  }

  var data = this.state.signpad.toDataURL('image/jpeg');
  this.setState({
    opensncdPopup:true,
    basestring : data
  })
  // this.download(data, "signature.jpeg");

}
saveasPng = () => {

  if (this.state.signpad.isEmpty()) {
    return alert("Please provide a signature first.");
  }
  var data = this.state.signpad.toDataURL('image/png');
  this.setState({
    opensncdPopup:true,
    basestring : data
  })
  this.props.baseString(data)
  this.props.hideSignpad();

  
  // this.download(data, "signature.png");
 

}


startDraw = () => {
  var ctx = this.state.canvas.getContext('2d');
  ctx.globalCompositeOperation = 'source-over'; // default value
}
startErase = () => {
  var ctx = this.state.canvas.getContext('2d');
  ctx.globalCompositeOperation = 'destination-out';
}
clearCanvas = () => {
  this.state.signpad.clear();
}

download = (dataURL, filename) => {
  var blob = dataURLtoBlob(dataURL);
  var url = window.URL.createObjectURL(blob);

  var a = document.createElement("a");
  a.style = "display: none";
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
}
 handleClickOpen = () => {
  this.setState({
    openPopUp: true
  })
};

 handleClose = () => {
  this.setState({
    openPopUp: false
  })
};
Closepopup = () => {
  this.setState({
    opensncdPopup: false
  })
};

    render(props){
        return(
          <div>
        
          <div className="wrapper" style={divstyle}>
            <canvas ref={this.canvas} id="signature-pad" className="signature-pad" width="552" height="400"></canvas>
            </div>
       
         <div style={btndivstyle}>
         <Button variant="outlined" color="secondary" onClick={this.clearCanvas}>Zurücksetzen</Button>

         <Button variant="outlined" color="primary" id="save-png" onClick={this.saveasPng}>Speichern</Button>
          {/* <Button variant="contained" color="primary" onClick={this.saveasJpeg}>Save as JPEG</Button>
          <Button variant="contained" color="primary" onClick={this.startDraw}>Draw</Button>
          <Button variant="contained" color="primary" onClick={this.startErase}>Erase</Button> */}
         </div>
         </div>
        )
    }
}
export default SignPad;
