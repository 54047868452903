
import IndividualLink from './IndividualLink'
import PublicLink from './PublicLink'
import PublicLinkN from './PublicLinkNew'
import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Fallback } from './pages/Fallback'
import { Footer } from './components/Footer'
import { AppContent } from './components/AppContent'
import { Header } from './components/Header'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import CloseIcon from '@material-ui/icons/Close';
import  CameraPro  from './pages/CameraPro'
import {CameraContext} from './contexts/CameraContext'
import PublicLinkExisting from './PublicLinkExisting'
var $ = require( "jquery" );

// import './css/App.css'


function App() {


 const dispatchCameraEvent = (showCamera: boolean) => {
  if(showCamera === true){
    $("body").addClass("removeScroll")
     } else {
    $("body").removeClass("removeScroll")
    }
  setShowCamera((prevState) => {
    const newState = { ...prevState }
    
    return Object.assign(newState, {
      showcam : showCamera
    })
  })
 };
 const setImagesourceEvent = (source: string) => {
  setShowCamera((prevState) => {
    const newState = { ...prevState }
    
    return Object.assign(newState, {
      source : source
    })
  })
 };
      
 const setSignpadEvent = (padId:string,source: string) => {
  setShowCamera((prevState) => {
    const newState = { ...prevState }
    
//  let items = [...newState.signpadsPath];
//  let myitem = items[index];
//  //@ts-ignore
//  myitem = source;
//  items[index] = myitem;
 // 5. Set the state to our new copy
    return Object.assign(newState, {
      padId:padId,
      signpadsPath:source
      // signpadsPath: {
      //   ...newState.signpadsPath,
      //   [name] :
      //    source

      // }
      
    })
  })
 };
      
      const camera = {
        showcam: false,
        source: "",
        signpadsPath: "",
        padId: "",
        setImagesourceEvent,
        setSignpadEvent,
        dispatchCameraEvent
      }
      const [showCamera, setShowCamera] = useState(camera);


  return (

    <CameraContext.Provider value={showCamera}>
          {showCamera.showcam === true ?<CameraPro /> : null }
          {showCamera.showcam === true ? <div style={{position:"absolute",top:"5px",right: "10px",zIndex:999,color:"#fff"}}><CloseIcon  style={{cursor:"pointer"}}  onClick={(e) => dispatchCameraEvent(false)} /></div> : null }

    <Header />
  
    <AppContent>
      <Switch>

        {/* <Route exact path="/">
          <Fallback />
        </Route> */}
        <Route exact path="/new/ip/:ip">
          <PublicLinkN />
        </Route>
        {/* <Route path="/datenschutz" children={<PrivacyPolicy />} /> */}

        {/* <Route
          path="/invitation/:invitationToken"
          children={<IndividualLink />}
        /> */}
        <Route exact path="/patient/:patientid/:templateid/ip/:ip" children={<PublicLinkExisting />} />

        <Route exact path="/new/:token/ip/:ip" children={<PublicLinkN />} />
       
        <Route exact path="/:token/ip/:ip" children={<PublicLink />} /> 
        {/* <Route  path="/new" children={<PublicLinkN />} /> */}
        <Route exact path="/" children={<PublicLink />} />
      
      </Switch>
    </AppContent>

    <Footer />
    </CameraContext.Provider>
  );
}

export default App;
