import React, { useContext } from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { FastField, FieldProps, Field, Form } from 'formik'
import { InputContainer, InputField } from '../components/FormElement'
import { FormattedMessage } from 'react-intl'
import { Card } from '../components/Card'
import { CardHeader } from '../components/CardHeader'
import DatePicker from '../components/DatePicker'
import Label from '../styled/Label'
import RequiredStar from '../styled/RequiredStar'
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import ReactFlagsSelect from 'react-flags-select';
import { PatientContext } from '../contexts/PatientContext'


const Wrapper = styled.div`
  .MuiGrid-item {
    margin: auto;
  }
`

export const PatientInfo = () => {
  const [newform, setnewform] = React.useState(false);
  const [country, setcountry] = React.useState("DE");
  const [insured_country, setinsured_country] = React.useState("DE");
  const PatientValues = useContext(PatientContext)
  React.useEffect(() => {
    if (PatientValues?.insurance_status === 'no') {
      setnewform(true)
    }else{
      setnewform(false)
    }
  }, [PatientValues]);
  return (
    <Card>
      <Wrapper>
        <CardHeader check={true} title={<FormattedMessage id="personalData.title" />} />
        <Grid container spacing={3}>
          <Grid item xs sm={12}>
            <InputContainer>
              <Label>
                <FormattedMessage id="Anrede" />
              </Label>
              <FastField name="patient.salutation">
                {({ field, form, meta }: FieldProps) => (
                  <RadioGroup
                    row
                    aria-label="salutation"
                    onChange={(e) => form.setFieldValue("patient.salutation", e.target.value)}
                    value={field.value}
                  >
                    <FormControlLabel
                      value="Mr"
                      control={<Radio color="primary" />}
                      label="Herr"
                    />
                    <FormControlLabel
                      value="Mrs"
                      control={<Radio color="primary" />}
                      label="Frau"
                    />
                  </RadioGroup>
                )}

              </FastField>
            </InputContainer>

          </Grid>

          <Grid item xs sm={2} className='mt-0'>
            <InputContainer>
              <Label>
                <FormattedMessage id="Titel" />
                <RequiredStar />
              </Label>
              <FastField name="patient.title">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="title"
                    inputProps={{ 'aria-label': 'title' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={4} className='mt-0'>
            <InputContainer>
              <Label>
                <FormattedMessage id="Vorname" />
                <RequiredStar />
              </Label>
              <FastField name="patient.first_name">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="first_name"
                    inputProps={{ 'aria-label': 'Vorname' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>

          <Grid item xs sm={6} className='mt-0'>
            <InputContainer>
              <Label>
                <FormattedMessage id="Nachname" />
                <RequiredStar />
              </Label>
              <FastField name="patient.last_name">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="last_name"
                    inputProps={{ 'aria-label': 'Nachname' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={4} className="mt-0 margin0 hundredchildWidth datepickerfieldheight">
            <DatePicker
              name="patient.date_of_birth"
              label="Geburtsdatum"

              required
            />

          </Grid>
          <Grid item xs sm={6} className="mt-0 margin0">
            <InputContainer>
              <Label>
                <FormattedMessage id="Gender" />
              </Label>
              <FastField name="patient.gender">
                {({ field, form, meta }: FieldProps) => (
                  <>
                    <RadioGroup
                      row
                      aria-label="gender"
                      onChange={(e) => form.setFieldValue("patient.gender", e.target.value)}
                      value={field.value}

                    >

                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="mannlich"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="weiblich"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio color="primary" />}
                        label="divers"
                      />
                    </RadioGroup>

                    {meta.touched && meta.error ? <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id="first_name-helper-text">Please Select </p> : ""}
                  </>
                )}
              </FastField>
              {/* <input type="hidden" name="patient.genderhere" value={gender}  /> */}


            </InputContainer>
          </Grid>
          <Grid item xs className='AdjustCenter' sm={12}>
            <CardHeader check={false} title={<FormattedMessage id="Anschrift" />} />
          </Grid>
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="Straße und Hausnummer " />
                <RequiredStar />
              </Label>
              <FastField name="patient.address1">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="anschrift"
                    inputProps={{ 'aria-label': 'anschrift' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>

          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="Straßenzusatz" />
                <RequiredStar />
              </Label>
              <FastField name="patient.address2">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="Starbenzusatz"
                    inputProps={{ 'aria-label': 'Starbenzusatz' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="Postleitzahl" />
                <RequiredStar />
              </Label>
              <FastField name="patient.zipcode">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="zipcode"
                    inputProps={{ 'aria-label': 'zipcode' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="Stadt" />
                <RequiredStar />
              </Label>
              <FastField name="patient.city">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="Stadt"
                    inputProps={{ 'aria-label': 'Stadt' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6} className="margin0 reactflags">
            <InputContainer>
              <Label>
                <FormattedMessage id="Land" />
                <RequiredStar />
              </Label>
              <FastField name="patient.country">
                {({ field, form, meta }: FieldProps) => (
                  <ReactFlagsSelect
                    selected={country}
                    onSelect={(code) => {
                      form.setFieldValue("patient.country", code);
                      setcountry(code)
                    }
                    }
                  />
                )}
              </FastField>
            </InputContainer>

          </Grid>
          <Grid item xs className='AdjustCenter' sm={12}>
            <CardHeader check={false} title={<FormattedMessage id="Kontaktmöglichkeiten" />} />
          </Grid>
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="Telefon" />
                <RequiredStar />
              </Label>
              <FastField name="patient.home_phone">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="Telefon"
                    inputProps={{ 'aria-label': 'Telefon' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="Telefon 2" />
                <RequiredStar />
              </Label>
              <FastField name="patient.work_phone">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="Telefon_2"
                    inputProps={{ 'aria-label': 'Telefon_2' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="Mobil" />
                <RequiredStar />
              </Label>
              <FastField name="patient.cellular_phone">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="Mobil"
                    inputProps={{ 'aria-label': 'Mobil' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6}>
            <InputContainer>
              <Label>
                <FormattedMessage id="fax" />
                <RequiredStar />
              </Label>
              <FastField name="patient.fax">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="fax"
                    inputProps={{ 'aria-label': 'fax' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>
          <Grid item xs sm={6} className="margin0">
            <InputContainer>
              <Label>
                <FormattedMessage id="E-mail" />
                <RequiredStar />
              </Label>
              <FastField name="patient.email">
                {({ field, meta }: FieldProps) => (
                  <InputField
                    type="text"
                    id="E-mail"
                    inputProps={{ 'aria-label': 'E-mail' }}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && meta.error ? true : false}
                    helperText={(meta.touched && meta.error) || ''}
                    {...field}
                  />
                )}
              </FastField>
            </InputContainer>
          </Grid>

          <Grid item xs sm={12} className="margin0">
            <FormLabel component="legend">
              Stimmen die Daten des Patienten mit denen des
              Hauptversicherten uberein?
            </FormLabel>
            <FastField name="patient.insurance_status">
              {({ field, form, meta }: FieldProps) => (
                <RadioGroup
                  row
                  aria-label="insuredPerson"
                  value={field.value}
                  onLoad={() => {  setnewform(field.value === "no" ? true : false) }}
                  onChange={(e) => { form.setFieldValue("patient.insurance_status", e.target.value); setnewform(e.target.value === "no" ? true : false) }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="primary" />}
                    label="Ja"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="primary" />}
                    label="Nein"
                  />
                </RadioGroup>
              )}
            </FastField>
          </Grid>
        </Grid>
      </Wrapper>
      {
        (newform === true) &&
        <Wrapper>
          {/* <CardHeader title={<FormattedMessage id="personalData.title" />} /> */}
          <Grid container spacing={3}>
            <Grid item xs className='AdjustCenter' sm={12}>
              <CardHeader check={false} title={<FormattedMessage id="Persönliche Angaben Versicherter" />} />
            </Grid>
            <Grid item xs sm={12}>
              <InputContainer>
                <Label>
                  <FormattedMessage id="Anrede" />
                </Label>
                <FastField name="patient.insured_salutation">
                  {({ field, form, meta }: FieldProps) => (
                    <RadioGroup
                      row
                      aria-label="salutation"
                      onChange={(e) => form.setFieldValue("patient.insured_salutation", e.target.value)}
                      // onChange={(e) => alert(e.target.value)}
                      value={field.value}

                    >
                      <FormControlLabel
                        value="Mr"
                        control={<Radio value="MR" color="primary" />}
                        label="Herr"
                      />
                      <FormControlLabel
                        value="Mrs"
                        control={<Radio value="MRS" color="primary" />}
                        label="Frau"
                      />
                    </RadioGroup>
                  )}
                </FastField>

              </InputContainer>

            </Grid>
            <Grid item xs sm={2} className='mt-0'>
              <InputContainer>
                <Label>
                  <FormattedMessage id="Titel" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_title">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="title"
                      inputProps={{ 'aria-label': 'title' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid>
            <Grid item xs sm={4} className='mt-0'>
              <InputContainer>
                <Label>
                  <FormattedMessage id="personalData.firstName" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_first_name">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="first_name"
                      inputProps={{ 'aria-label': 'Vorname' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid>

            <Grid item xs sm={6} className='mt-0'>
              <InputContainer>
                <Label>
                  <FormattedMessage id="personalData.lastName" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_last_name">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="last_name"
                      inputProps={{ 'aria-label': 'Nachname' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid>
            <Grid item xs sm={6} className="mt-0 margin0 hundredchildWidth datepickerfieldheight">
              <DatePicker
                name="patient.insured_date_of_birth"
                label="personalData.dateOfBirth"
                required
              />
            </Grid>
            {/* <Grid item xs sm={6}>
              <InputContainer>
                <Label>
                  <FormattedMessage id="Anschrift" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.anschrift">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="anschrift"
                      inputProps={{ 'aria-label': 'anschrift' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid> */}
            <Grid item xs className='AdjustCenter' sm={12}>
              <CardHeader check={false} title={<FormattedMessage id="Anschrift Versicherter" />} />
            </Grid>
            <Grid item xs sm={6}>
              <InputContainer>
                <Label>
                  <FormattedMessage id="Straße und Hausnummer " />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_address1">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="anschrift"
                      inputProps={{ 'aria-label': 'anschrift' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid>
            <Grid item xs sm={6}>
              <InputContainer>
                <Label>
                  <FormattedMessage id="Straßenzusatz" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_address2">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="Starbenzusatz"
                      inputProps={{ 'aria-label': 'Starbenzusatz' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid>
            <Grid item xs sm={6}>
              <InputContainer>
                <Label>
                  <FormattedMessage id="Postleitzahl" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_zipcode">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="Postleitzahl"
                      inputProps={{ 'aria-label': 'Postleitzahl' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid>
            <Grid item xs sm={6}>
              <InputContainer>
                <Label>
                  <FormattedMessage id="Stadt" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_city">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="Stadt"
                      inputProps={{ 'aria-label': 'Stadt' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid>
            <Grid item xs sm={6} className="margin0 reactflags">
              <InputContainer>
                <Label>
                  <FormattedMessage id="insured_country" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_country">
                  {({ field, form, meta }: FieldProps) => (
                    <ReactFlagsSelect
                      selected={insured_country}
                      onSelect={(code) => {
                        form.setFieldValue("patient.insured_country", code)
                        setinsured_country(code)
                      }}
                    />
                  )}
                </FastField>
              </InputContainer>

            </Grid>
            <Grid item xs className='AdjustCenter' sm={12}>
              <CardHeader check={false} title={<FormattedMessage id="Kontaktmöglichkeiten Versicherter" />} />
            </Grid>
            <Grid item xs sm={6} className="margin0">
              <InputContainer>
                <Label>
                  <FormattedMessage id="Telefon" />
                  <RequiredStar />
                </Label>
                <FastField name="patient.insured_phone">
                  {({ field, meta }: FieldProps) => (
                    <InputField
                      type="text"
                      id="Telefon"
                      inputProps={{ 'aria-label': 'Telefon' }}
                      variant="outlined"
                      fullWidth
                      error={meta.touched && meta.error ? true : false}
                      helperText={(meta.touched && meta.error) || ''}
                      {...field}
                    />
                  )}
                </FastField>
              </InputContainer>
            </Grid>
          </Grid>
        </Wrapper>
      }
    </Card>
  )
}
