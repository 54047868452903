import { Container, Grid } from '@material-ui/core'
import axios from 'axios'
import useAxios from 'axios-hooks'
import { Form, Formik } from 'formik'
import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { FormError } from '../../components/FormError'
import { FormFocusError } from '../../components/FormFocusError'
import { StepButtonsexist } from '../../components/StepButtons'
import { StepContent } from '../../components/StepContentExisting'
import { StepperExisting } from '../../components/Stepper'
import { redirecttoServer, sitePath, submissionServer, submissionServerExistingPatient } from '../../config'
import {
  DocumentFlowContextexisting,
  getStepsexist,
} from '../../contexts/DocumentFlowContext'
import { PatientContext } from '../../contexts/PatientContext'
import { AnamnesisSubmission } from '../../types/AnamnesisSubmission'
import {
  assembleSubmission,
  generateInitialConsentValues,
  generateInitialFixedStepValues,
  generateInitialFormValues,
} from '../../utils/formHelpers'
import { Loading } from '../Loading'
import { getFixedStepsExisting } from './fixedSteps'

interface Props {
  extraSubmissionData: { [key in string]?: {} }
}

export const DocumentFlowExistingPatient = ({ extraSubmissionData }: Props) => {
  const { patientid, ip } = useParams<any>();
  const { formatMessage } = useIntl()
  const [steps, setsteps] = React.useState<any>();
  const { templates, setStage, stepIndex, setStepIndex } = useContext(
    DocumentFlowContextexisting,
  )
  // const PatientValues = getPatient("patient")
  const PatientValues = useContext(PatientContext)
  const [abc, setabc] = React.useState<any>({});
  const [refreshKey, setRefreshKey] = React.useState(0);

  useEffect(() => {
    const fixedSteps = getFixedStepsExisting(formatMessage, PatientValues)
    const stepss = getStepsexist(fixedSteps, templates)
    let initialPatientValues = generateInitialFixedStepValues(fixedSteps[0])
    setabc(initialPatientValues)
    setsteps(stepss)
    setRefreshKey(prevKey => prevKey + 1); 
  }, [PatientValues]);
  const [{ loading, error }, submitForm] = useAxios<AnamnesisSubmission>(
    {
      url: `https://${ip}${submissionServer}`,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    },
    {
      manual: true,
    },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  })
  // var arrUrl = window.location.href.replace(sitePath + "patient/", "").split("/");
  // var ptid = arrUrl[arrUrl.length - 2];

  if (loading) return <Loading />
  return (
    <>
      <Formik
       key={refreshKey} 
        initialValues={{
          patientId: parseInt(patientid),
          ...abc,
          ...generateInitialFormValues(templates),
          ...generateInitialConsentValues(templates),
        }}
        // validationSchema={steps[0].validationSchema}
        onSubmit={(values, formikHelpers) => {
          if (stepIndex < steps.length - 2) {
            setStepIndex(stepIndex + 1)
            formikHelpers.setTouched({})
            formikHelpers.setSubmitting(false)
          } else {
            var arrUrl = window.location.href.replace(sitePath + "patient/", "").split("/");
            //var ptid = arrUrl[arrUrl.length - 2];
            const data = {
              ...assembleSubmission(values as any, templates),
              ip:ip
              //...extraSubmissionData,
            }
            var tempId = arrUrl[arrUrl.length - 3]
            var requiredUrl = 'https://' + ip + submissionServerExistingPatient;
            axios({
              method: 'POST',
              url: requiredUrl,
              data: data,
              headers: {
                //'formData': JSON.stringify(data),
                'practiceId': tempId.split('%')[1],
                //'patid': ptid
              }

            }).then((result: any) => {
              if (result.data.ptid !== 0 || result.data.ptid !== "0") {
                if (result.data.navigateto === "allpatients") {
                  window.location.assign(redirecttoServer + "patientlist");
                }
                else if (result.data.navigateto === "Detailpatients") {
                  window.location.assign(redirecttoServer + "itemDetails/" + result.data.ptid);
                }
                else {
                  window.location.assign(redirecttoServer + "itemDetails/" + result.data.ptid);
                }
              }
              setStage('thankYou')
            });

            // submitForm({ data }).then(() => {
            //   setStage('thankYou')
            // })
          }
        }}>
        {!!templates && templates.length > 0 ?
          <Form>

            <Container maxWidth="lg">
              <StepperExisting steps={steps} />
            </Container>
            {error && <FormError />}
            <Grid container direction="column">
              <StepContent stepIndex={stepIndex} steps={steps} />
            </Grid>
            <Container maxWidth="md">
              <StepButtonsexist
                stepIndex={stepIndex}
                steps={steps}
                setStepIndex={setStepIndex}
              />
            </Container>
            <FormFocusError />
          </Form>
          : null}
      </Formik>

    </>
  )
}
