import React from 'react'
import styled from 'styled-components'
import { ActionButton } from './ActionButton'
import { FormattedMessage } from 'react-intl'
import { StepInterface } from '../types/Step'
import { theme } from '../utils/theme'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;

  ${theme.breakpoints.down('sm')} {
    padding-bottom: 40px;
  }
`

const MultiButtonWrapper = styled(ButtonWrapper)`
  justify-content: space-between;
`

interface StepButtonProps {
  stepIndex: number
  steps: StepInterface[]
  setStepIndex: (stepIndex: number) => void
}

export const StepButtons = (props: StepButtonProps) => {
  const { stepIndex, steps, setStepIndex } = props
  const BackButton = () => (
    <ActionButton
      title={<FormattedMessage id="documentFlow.backButton" />}
      action={() => setStepIndex(stepIndex - 1)}
    />
  )
  const NextButton = () => (
    <ActionButton
      title={<FormattedMessage id="documentFlow.saveButton" />}
      type="submit"
      primary
    />
  )
  const SubmitButton = () => (
    <ActionButton
      title={<FormattedMessage id="documentFlow.submitButton" />}
      type="submit"
      primary
    />
  )
  return (
    <ButtonWrapper>
      {stepIndex < steps.length - 1 ? (
        stepIndex > 0 ? (
          <MultiButtonWrapper>
            <BackButton />
            <NextButton />
          </MultiButtonWrapper>
        ) : (
          <NextButton />
        )
      ) : (
        <SubmitButton />
      )}
    </ButtonWrapper>
  )
}
export const StepButtonsexist = (props: StepButtonProps) => {
  const { stepIndex, steps, setStepIndex } = props
  const BackButton = () => (
    <ActionButton
      title={<FormattedMessage id="documentFlow.backButton" />}
      action={() => setStepIndex(stepIndex - 1)}
    />
  )
  const NextButton = () => (
    <ActionButton
      title={<FormattedMessage id="documentFlow.saveButton" />}
      type="submit"
      primary
    />
  )
  const SubmitButton = () => (
    <ActionButton
      title={<FormattedMessage id="documentFlow.submitButton" />}
      type="submit"
      primary
    />
  )

  return (
    <ButtonWrapper>
      {stepIndex < steps.length - 2 ? (
        stepIndex > 0 ? (
          <MultiButtonWrapper>
            <BackButton />
            <NextButton />
          </MultiButtonWrapper>
        ) : (
          <NextButton />
        )
      ) : (
        <SubmitButton />
      )}
    </ButtonWrapper>
  )
}
