import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import CreateIcon from '@material-ui/icons/Create';
import {
  useFormikContext
} from 'formik';
import { ImageServer } from "../config";
import { CameraContext } from '../contexts/CameraContext';
import DrawOnImage from "../pages/DrawOnImage";
const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const imgadjust = {
  width:"80%",
  margin:"0 auto 110px auto",
  // height:"400px",
  textAlign: "center"

}

export default function DrawableImageInForm(props) {
  const [open, setOpen] = React.useState(false);
  const [openpopup2, setOpenpopup2] = React.useState(false);
  const { source } = React.useContext(CameraContext);
  const { setValues, setFieldValue } = useFormikContext()

  const [imgurl, setimgurl] = React.useState(props.elsrc);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenpopup2(false)
  };
  const OpenPopUp2 = () => {
    if(imgurl.includes(ImageServer)){
      axios({
        method: 'Get',
        url: ImageServer+"api/public/v1/anamnesis_flow_submissions/imageonServer",
        headers:{
          "imageonServer":imgurl.replace(ImageServer,"")
        }
      }).then(response => {
        setimgurl(response.data)
        setOpenpopup2(true)
        });
    } else if(imgurl.includes("http") || imgurl.includes("https")){
      axios({
        method: 'Get',
        url: ImageServer+"api/public/v1/anamnesis_flow_submissions/imageurl",
        headers:{
          "imgurl":imgurl.replace(ImageServer,"")
        }
      }).then(response => {
        setimgurl(response.data)
        setOpenpopup2(true)
        })
        .catch(response => {
        });

    }
    else{
      setOpenpopup2(true)
    }
    

    
  };
  const ClosePopUp2 = () => {
    setOpenpopup2(false);
  };
  const getImgBase = (base) => {
    const data={
      "Logo":base,
    };
    axios({
      method: 'Post',
      url: ImageServer+"api/public/v1/anamnesis_flow_submissions/saveimage",
      data:data
    }).then(response => {
      setFieldValue(props.id, response.data)
      });
      
    setimgurl(base)
  }


  return (
    <div>
      <div style={imgadjust}>
        <div>
        <img src={imgurl} alt=""  style={{width:"100%",height:"100%"}} />
        </div>
        <div style={{textAlign:"center"}}>
          <Button
            variant="contained"
            color="default"
            startIcon={<CreateIcon style={{color:"#015270"}} />}
            style={{color:"#015270"}}
            onClick={OpenPopUp2}
            >
            Zeichnen
          </Button>
        </div>

        </div>
      {openpopup2 && <DrawOnImage getimg={getImgBase} setBaseUrl={imgurl} ClosepopUp2={ClosePopUp2} OpenPopUp2={openpopup2} />}
    </div>
  );
}
