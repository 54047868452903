import React, { useState } from 'react'
import { Practice } from '../types/Practice'
import { PatientContext } from './PatientContext'
import { PatientInterface } from '../types/Patient'

export const PatientProvide = (props: any) => {
    const setPatient = (patient: PatientInterface) => {
        
        //@ts-ignore
        updatePatient(patient)
        // updatePatient((prevState) => {
        //     const newState = { ...prevState }
        //     return Object.assign(newState, {
        //         ...patient,
        //     })
        // })
    }
    

    const patientState = {
        salutation: 'Mr',
        title: '',
        first_name: '',
        last_name: '',
        date_of_birth: '',
        gender: '',
        address1: '',
        address2: '',
        zipcode: '',
        city: '',
        country: '',
        home_phone: '',
        work_phone: '',
        cellular_phone: '',
        fax: '',
        email: '',
        insured_salutation: '',
        insured_title: '',
        insured_first_name: '',
        insured_last_name: '',
        insured_date_of_birth: '',
        insured_address1: '',
        insured_address2: '',
        insured_zipcode: '',
        insured_city: '',
        insured_country: '',
        insured_phone: '',
        insurance_status: '',
        setPatient,
    }

    const [patientValue, updatePatient] = useState(patientState)

    return (
        <PatientContext.Provider value={patientValue}>
            {props.children}
        </PatientContext.Provider>
    )
}
