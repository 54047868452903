import React from 'react'
import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { Spacer } from './FormElement'

export const FormSpacer = () => (
  <Spacer
    element={{
      id: 'patientInfo-spacer',
      type: 'spacer',
      style: { size: 's' },
    }}
  />
)

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`
const Wrapper2 = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: center;
`

interface CardHeaderProps {
  title: JSX.Element
  check: boolean
}

export const CardHeader = (props: CardHeaderProps) => (
  <>
    <Grid item>
      {props.check === true ? 
       <Wrapper>
       <Typography variant="h1">{props.title}</Typography>
     </Wrapper> :
      <Wrapper2>
      <Typography variant="h1">{props.title}</Typography>
    </Wrapper2>
    }
     
    </Grid>
    <FormSpacer />
  </>
)
