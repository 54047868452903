import { createContext } from 'react'
import {
  DocumentTemplateInterface,
  DocumentFlowStage,
} from '../types/DocumentFlow'
import { StepInterface } from '../types/Step'

export const getSteps = (
  fixedSteps: StepInterface[],
  templates: DocumentTemplateInterface[],
) => {
  return [fixedSteps[0]]
    .concat(templates)
    .concat([fixedSteps[fixedSteps.length - 1]])
}
export const getStepsexist = (
  fixedSteps: StepInterface[],
  templates: any,
) => {
  return [templates]
    .concat(templates)
    .concat([fixedSteps[fixedSteps.length - 1]])
}

export const DocumentFlowContext = createContext({
  stepIndex: 0,
  stage: 'documentFlow' as DocumentFlowStage,
  templates: [] as DocumentTemplateInterface[],
  isLoading: false,
  setStepIndex: (stepIndex: number) => {},
  setStage: (stage: DocumentFlowStage) => {},
  setTemplates: (templates: DocumentTemplateInterface[]) => {},
  setIsLoading: (isLoading: boolean) => {},
})
export const DocumentFlowContextexisting = createContext({
  stepIndex: 1,
  stage: 'documentFlow' as DocumentFlowStage,
  templates: [] as DocumentTemplateInterface[],
  isLoading: false,
  setStepIndex: (stepIndex: number) => {},
  setStage: (stage: DocumentFlowStage) => {},
  setTemplates: (templates: DocumentTemplateInterface[]) => {},
  setIsLoading: (isLoading: boolean) => {},
})
