import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { PracticeContext } from '../contexts/PracticeContext'
import { FormattedMessage } from 'react-intl'
import { Card } from '../components/Card'
import styled from 'styled-components'
import { theme } from '../utils/theme'
import { ActionButton } from '../components/ActionButton'
import { getUrl } from '../utils/urlHelpers'

const Wrapper = styled.div`
  padding-top: 20px;
  text-align: center;

  ${theme.breakpoints.down('sm')} {
    padding-top: 10px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
`

export const ThankYou = () => {
  const { website } = useContext(PracticeContext)
  const websiteUrl = getUrl(website)

  return (
    <Wrapper>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1">
              <FormattedMessage id="thankYou.title" />
            </Typography>
            <Typography variant="h2">
              <FormattedMessage id="thankYou.successMessage" />
            </Typography>
            {website ? (
              <ButtonWrapper>
                <ActionButton
                  title={<FormattedMessage id="thankYou.backToPractice" />}
                  href={websiteUrl}
                  primary
                />
              </ButtonWrapper>
            ) : (
              <p>
                <FormattedMessage id="thankYou.closeWindow" />
              </p>
            )}
          </Grid>
        </Grid>
      </Card>
    </Wrapper>
  )
}
