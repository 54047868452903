import useAxios from 'axios-hooks'
import React, { useContext, useEffect } from 'react'
import { sitePath, templateServer } from './config'
import { DocumentFlowContext } from './contexts/DocumentFlowContext'
import { LanguageContext } from './contexts/LanguageContext'
import { PracticeContext } from './contexts/PracticeContext'
import { DocumentFlow } from './pages/DocumentFlow/IndexNew'
import { AnamnesisFlow } from './types/AnamnesisFlow'
import { getLanguagesSubset } from './utils/languageHelpers'
import { useParams } from 'react-router-dom'

const PublicLinkN = () => {
  const { token } = useParams<any>()
  // const [token, settoken] = React.useState("")
  const { setPractice, loaded } = useContext(PracticeContext)
  const { stage, setTemplates, setIsLoading } = useContext(DocumentFlowContext)
  const { setSupportedLanguages } = useContext(LanguageContext)
  const [{ loading, error }, requestData] = useAxios<AnamnesisFlow>(
    `${templateServer}/${token}`,
    {
      manual: true,
    },
  )

  useEffect(() => {
    //  settoken(window.location.href.replace(sitePath+"new/",""));
    if(!!token){
     setIsLoading(true)

    requestData()
    .then((result) => {
      localStorage.setItem("practiceId",result.data.practice.id)
      setPractice(result.data.practice)

      for (let i = 0; i < result.data.document_templates.length; i++) {
        result.data.document_templates[i].atn = JSON.parse(result.data.document_templates[i].atn.toString());
        result.data.document_templates[i].languages = JSON.parse(result.data.document_templates[i].languages.toString());
        
      }
      setTemplates(result.data.document_templates)
      setSupportedLanguages(
        getLanguagesSubset(result.data.document_templates),
      )
      setIsLoading(false)
    })
    .catch(() => setIsLoading(false))
   } else{
    setIsLoading(false)
   }
  }, [
    requestData,
    setPractice,
    setTemplates,
    setIsLoading,
    setSupportedLanguages,
  ])
  // TODO optimise re-rendering

//   if (loading) return <Loading />
//   if (error) return <Fallback error />
   
        return <DocumentFlow extraSubmissionData={{ token }} />
    
 
}

export default PublicLinkN
