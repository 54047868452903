import { object, string, number, } from 'yup'
import { PatientInterface } from '../../types/Patient'
import { StepInterface } from '../../types/Step'
import { devMode } from '../../config'
import { MessageDescriptor } from 'react-intl'
import { PrimitiveType } from '../../types/PrimitiveType'
import { boolean } from 'yup/lib/locale'

export const getFixedSteps = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType> | undefined,
  ) => string,
): StepInterface[] => [
    {
      id: 'patient',
      title: formatMessage({ id: 'personalData.title' }),
      initialValues: {
        first_name: devMode ? 'Carl' : '',
        last_name: devMode ? 'Dau' : '',
        date_of_birth: devMode ? '1990-10-10' : null,
      } as PatientInterface,
      validationSchema: object().shape({
        patient: object().shape({
          first_name: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          last_name: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
        }),
      }),
    },
    {
      id: 'summary',
      title: formatMessage({ id: 'summary.title' }),
    },
  ]

export const getFixedStepsNew = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType> | undefined,
  ) => string,
): StepInterface[] => [
    {
      id: 'patient',
      title: formatMessage({ id: 'personalData.title' }),
      initialValues: {
        first_name: devMode ? 'Carl' : '',
        last_name: devMode ? 'Dau' : '',
        //@ts-ignore
        date_of_birth: devMode ? '1990-10-10' : null,
        gender: '',
        address1: '',
        zipcode: '',
        city: '',
        insured_first_name: '',
        insured_last_name: '',
        insured_date_of_birth: '',
        insured_address1: '',
        insured_zipcode: '',
        insurance_status: '',
        insured_city: ''

      },
      validationSchema: object().shape({
        patient: object().shape({

          first_name: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          insured_first_name: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),

          last_name: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),

          insured_last_name: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),
          insured_date_of_birth: string().when("insurance_status", {
            is: "no",
            then: string()
              .required("Ungultiges Datum."),
          }),
          gender: string()
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          address1: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          insured_address1: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),
          zipcode: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          insured_zipcode: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),
          city: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          insured_city: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),
        }),
      }),
    },
    {
      id: 'summary',
      title: formatMessage({ id: 'summary.title' }),
    },
  ]

export const getFixedStepsExisting = (
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType> | undefined,
  ) => string, patient: PatientInterface
): StepInterface[] => [
    {
      id: 'patient',
      title: formatMessage({ id: 'personalData.title' }),
      initialValues: {
        salutation: patient?.salutation ?? '',
        title: patient?.title ?? '',
        first_name: devMode ? 'Carl' : patient?.first_name ?? '',
        last_name: devMode ? 'Dau' : patient?.last_name ?? '',
        //@ts-ignore
        date_of_birth: devMode ? '1990-10-10' : patient?.date_of_birth ?? '',
        gender: patient?.gender ?? '',
        address1: patient?.address1 ?? '',
        address2: patient?.address2 ?? '',
        zipcode: patient?.zipcode ?? '',
        city: patient?.city ?? '',
        country: patient?.country ?? '',
        home_phone: patient?.home_phone ?? '',
        cellular_phone: patient?.cellular_phone ?? '',
        work_phone: patient?.work_phone ?? '',
        fax: patient?.fax ?? '',
        email: patient?.email ?? '',
        insurance_status: patient?.insurance_status ?? '',

        insured_salutation: patient?.insured_salutation ?? '',
        insured_title: patient?.insured_title ?? '',
        insured_first_name: patient?.insured_first_name ?? '',
        insured_last_name: patient?.insured_last_name ?? '',
        insured_date_of_birth: patient?.insured_date_of_birth ?? '',
        insured_address1: patient?.insured_address1 ?? '',
        insured_address2: patient?.insured_address2 ?? '',
        insured_zipcode: patient?.insured_zipcode ?? '',
        insured_city: patient?.insured_city ?? '',
        insured_country: patient?.insured_country ?? '',
        insured_phone: patient?.insured_phone ?? '',

      },
      validationSchema: object().shape({
        patient: object().shape({

          first_name: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          insured_first_name: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),

          last_name: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),

          insured_last_name: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),
          insured_date_of_birth: string().when("insurance_status", {
            is: "no",
            then: string()
              .required("Ungultiges Datum."),
          }),
          gender: string()
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          address1: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          insured_address1: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),
          zipcode: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          insured_zipcode: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),
          city: string()
            .min(2, formatMessage({ id: 'error.textTooShort' }))
            .max(50, formatMessage({ id: 'error.textTooLong' }))
            .required(formatMessage({ id: 'error.requiredTextInput' })),
          insured_city: string().when("insurance_status", {
            is: "no",
            then: string().min(2, formatMessage({ id: 'error.textTooShort' }))
              .max(50, formatMessage({ id: 'error.textTooLong' }))
              .required(formatMessage({ id: 'error.requiredTextInput' })),
          }),
        }),
      }),
    },
    {
      id: 'summary',
      title: formatMessage({ id: 'summary.title' }),
    },
  ]
