import React from 'react'
import { DocumentTemplate } from '../pages/DocumentTemplate'
import { PatientInfo } from '../pages/PatientInfo'
import { StepInterface } from '../types/Step'
import { Summary } from '../pages/Summary'

interface StepContentProps {
  stepIndex: number
  steps: StepInterface[]
}

export const StepContent = (props: StepContentProps) => {
  const lastIndex = props.steps.length - 1
  switch (props.stepIndex) {
    case 0:
      return <PatientInfo />
    case lastIndex:
      return <Summary />
    default:
      return <DocumentTemplate />
  }
}
