import { DocumentFlowStage } from './types/DocumentFlow'

// New Local Url's
// export const templateServer = 'https://localhost:5002/api/v1/AnamneseHome/templates'
// export const redirecttoServer = 'http://localhost:8002/'
// export const ImageServer = 'https://localhost:5002/'
// export const submissionServer = '/api/public/v1/anamnesis_flow_submissions/PatientDetail'
// export const submissionServerExistingPatient = '/api/public/v1/anamnesis_flow_submissions/PatientTemplate'
// export const sitePath = 'https://localhost:8003/'



// Client server
 export const templateServer ='https://api.altona.app/api/v1/AnamneseHome/templates'
 export const redirecttoServer ='https://app.altona.app/' 
 export const ImageServer ='https://api.altona.app/'  
 export const submissionServer ='/api/public/v1/anamnesis_flow_submissions/PatientDetail'
 export const submissionServerExistingPatient ='/api/public/v1/anamnesis_flow_submissions/PatientTemplate'
 export const sitePath = 'https://intern.altona.app/'


export const athenaUrl = 'https://dampsoft.de/athena'
export const privacyPolicyUrl = 'https://www.dampsoft.de/datenschutz/'
export const imprintUrl = 'https://www.dampsoft.de/impressum/'

/*
 / ########################################
 / ####### DEV MODE - Testing Only ########
 / ########################################
*/

export const devMode = false

export const devStage = 'documentFlow' as DocumentFlowStage
export const devStepIndex = 0
export const fillAnamneseTemplate = true
export const fillDatenschutzTemplate = true
export const fillTestTemplate = true
export const fillConsents = true
