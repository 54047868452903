import React, { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { AnamnesisFlow } from './types/AnamnesisFlow'
import { PublicLinkParams } from './types/Params'
import { DocumentFlow } from './pages/DocumentFlow/index'
import { DocumentFlowContext } from './contexts/DocumentFlowContext'
import { PracticeContext } from './contexts/PracticeContext'
import { templateServer,sitePath } from './config'
import { ThankYou } from './pages/ThankYou'
import { useParams } from 'react-router-dom'
import { Welcome } from './pages/Welcome'
import { Fallback } from './pages/Fallback'
import { Loading } from './pages/Loading'
import { getLanguagesSubset } from './utils/languageHelpers'
import { LanguageContext } from './contexts/LanguageContext'
import { ATNType } from './types/ATNTypes'

const PublicLink = () => {
  const { token  } = useParams<any>()
  // const [token, settoken] = React.useState("")
  const { setPractice, loaded } = useContext(PracticeContext)
  const { stage, setTemplates, setIsLoading } = useContext(DocumentFlowContext)
  const { setSupportedLanguages } = useContext(LanguageContext)

  const [{ loading, error }, requestData] = useAxios<AnamnesisFlow>(
    `${templateServer}/${token}`,
    {
      manual: true,
    },
  )

  useEffect(() => {
    // settoken(window.location.href.replace(sitePath,""));  
    if(!!token){
      setIsLoading(true)
      requestData()
      .then((result) => {
        localStorage.setItem("practiceId",result.data.practice.id)
        setPractice(result.data.practice)
        for (let i = 0; i < result.data.document_templates.length; i++) {
          result.data.document_templates[i].atn = JSON.parse(result.data.document_templates[i].atn.toString());
          result.data.document_templates[i].languages = JSON.parse(result.data.document_templates[i].languages.toString());
        }
        setTemplates(result.data.document_templates)
        setSupportedLanguages(
          getLanguagesSubset(result.data.document_templates),
        )
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }}, [
    requestData,
    setPractice,
    setTemplates,
    setIsLoading,
    setSupportedLanguages,
  ])

  // TODO optimise re-rendering

  if (loading) return <Loading />
  if (error) return <Fallback error />
    switch (stage) {
      case 'welcome':
        return <Welcome />
      case 'documentFlow':
        return <DocumentFlow extraSubmissionData={{ token }} />
      case 'thankYou':
        return <ThankYou />
    }
  
  return null;
}

export default PublicLink
