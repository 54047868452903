import React, { useContext } from 'react'
import styled from 'styled-components'
import { colors, theme } from '../utils/theme'
import {
  Step,
  StepLabel,
  Stepper as MaterialStepper,
  MobileStepper,
  Button,
} from '@material-ui/core'
import { StepInterface } from '../types/Step'
import { DocumentFlowContext, DocumentFlowContextexisting } from '../contexts/DocumentFlowContext'
import { FormattedMessage } from 'react-intl'

const StepperContainer = styled.div`
  padding-top: 10px;
`

const DesktopFlowStepper = styled(MaterialStepper)`
  && {
    background-color: ${colors.lightGrey};

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`

const MobileFlowStepper = styled(MobileStepper)`
  && {
    background-color: ${colors.lightGrey};
    font-family: Arial, 'Helvetica Neue', sans-serif;

    ${theme.breakpoints.up('md')} {
      display: none;
    }
  }
`

const FlowStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .MuiStepLabel-label.MuiStepLabel-active {
    font-weight: 700;
    color: ${colors.grey};
  }
`

interface StepperProps {
  steps: StepInterface[]
}

export const Stepper = (props: StepperProps) => {
  const { setStepIndex, stepIndex } = useContext(DocumentFlowContext)
  const { steps } = props

  return (
    <StepperContainer>
      <DesktopFlowStepper activeStep={stepIndex} alternativeLabel>
        {steps.map((step) => (
          <Step key={step.id}>
            <FlowStepLabel>{step.title}</FlowStepLabel>
          </Step>
        ))}
      </DesktopFlowStepper>
      <MobileFlowStepper
        variant="text"
        steps={steps.length}
        position="static"
        activeStep={stepIndex}
        nextButton={
          <Button
            type={'submit'}
            size="small"
            disabled={stepIndex === steps.length - 1}>
            <FormattedMessage id="documentFlow.nextButton" />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => setStepIndex(stepIndex - 1)}
            disabled={stepIndex === 0}>
            <FormattedMessage id="documentFlow.backButton" />
          </Button>
        }
      />
    </StepperContainer>
  )
}
export const StepperExisting = (props: StepperProps) => {
  const { setStepIndex, stepIndex } = useContext(DocumentFlowContextexisting)
  const { steps } = props

  return (
    <StepperContainer>
      <DesktopFlowStepper activeStep={stepIndex} alternativeLabel>
        {steps.map((step) => {
         return !!step.title ? <Step key={step.id}><FlowStepLabel>{step.title}</FlowStepLabel></Step>: null})}
      </DesktopFlowStepper>
      <MobileFlowStepper
        variant="text"
        steps={steps.length}
        position="static"
        activeStep={stepIndex}
        nextButton={
          <Button
            type={'submit'}
            size="small"
            disabled={stepIndex === steps.length - 1}>
            <FormattedMessage id="documentFlow.nextButton" />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => setStepIndex(stepIndex - 1)}
            disabled={stepIndex === 0}>
            <FormattedMessage id="documentFlow.backButton" />
          </Button>
        }
      />
    </StepperContainer>
  )
}
