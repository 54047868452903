import React, { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { AnamnesisFlow } from './types/AnamnesisFlow'
import { DocumentFlowExistingPatient } from './pages/DocumentFlow/indexExisting'
import { DocumentFlowContextexisting } from './contexts/DocumentFlowContext'
import { PracticeContext } from './contexts/PracticeContext'
import { PatientContext } from './contexts/PatientContext'
import { templateServer, sitePath } from './config'
import { getLanguagesSubset } from './utils/languageHelpers'
import { LanguageContext } from './contexts/LanguageContext'
import { useParams } from 'react-router-dom'
import { PatientInterface } from './types/Patient' 

const PublicLinkExisting = () => {
  const { patientid, templateid, ip } = useParams<any>()
  const { setPractice, loaded } = useContext(PracticeContext)
  const { setPatient } = useContext(PatientContext)
  const { stage, setTemplates, setIsLoading } = useContext(DocumentFlowContextexisting)
  const { setSupportedLanguages } = useContext(LanguageContext)
  const [{ loading, error }, requestData] = useAxios<AnamnesisFlow>(
    // `${templateServer}/${templateid}/${ip}/${patientid}`,
    `https://${ip}/api/v1/Patients/getPatient/${templateid}/${patientid}`,
    {
      manual: true,
    },
  )
  // const [load, requestPatientData] = useAxios<PatientInterface>(
  //   `https://${ip}/api/v1/Patients/getPatient/${patientid}`,
  //   {
  //     manual: true,
  //   },
  // )


  const fetchdata = async () => {
    await requestData()
      .then((result) => {
        localStorage.setItem("practiceId", result.data.practice.id)
        setPractice(result.data.practice)
        for (let i = 0; i < result.data.document_templates.length; i++) {
          result.data.document_templates[i].atn = JSON.parse(result.data.document_templates[i].atn.toString());
          result.data.document_templates[i].languages = JSON.parse(result.data.document_templates[i].languages.toString());
        }
        setTemplates(result.data.document_templates)
        setPatient(result.data.patient)
        setSupportedLanguages(
          getLanguagesSubset(result.data.document_templates),
        )
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }
  // const fetchPatientData = async () => {
  //   await requestPatientData()
  //     .then((result) => {
  //       setPatient(result.data)
  //       setIsLoading(false)
  //     })
  //     .catch(() => setIsLoading(false))
  // }

  //useEffect(() => {
  //   fetchdata()
  // }, [])

  useEffect(() => {
    // var baseurl = window.location.href.replace(sitePath+"patient/","")
    // settoken1( baseurl.split("/")[baseurl.split("/").length -1]);
    // settoken( baseurl.split("/")[baseurl.split("/").length -1].split('%')[0]);

    if (!!patientid) {
      setIsLoading(true)
      fetchdata()
      // fetchPatientData()
    } else {
      setIsLoading(false)
    }
  }, [
    requestData,
    setPractice,
    setTemplates,
    setIsLoading,
    setSupportedLanguages,
  ])
  // TODO optimise re-rendering

  //   if (loading) return <Loading />
  //   if (error) return <Fallback error />

  return <DocumentFlowExistingPatient extraSubmissionData={{ patientid }} />


}

export default PublicLinkExisting
