import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ActionButton } from '../components/ActionButton'
import { Grid, Typography, Link } from '@material-ui/core'
import { DocumentFlowContext } from '../contexts/DocumentFlowContext'
import { PracticeContext } from '../contexts/PracticeContext'
import { FormattedMessage } from 'react-intl'
import { LanguagePickerRow } from '../components/LanguagePickerRow'
import { Card } from '../components/Card'
import { colors, theme } from '../utils/theme'

const Wrapper = styled.div`
  padding-top: 20px;

  ${theme.breakpoints.down('sm')} {
    padding-top: 10px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  ${theme.breakpoints.down('sm')} {
    padding-bottom: 40px;
  }
`

const Titles = styled.div`
  ${theme.breakpoints.down('sm')} {
    text-align: center;
  }
`

const PracticName = styled.div`
  display: none;

  ${theme.breakpoints.down('sm')} {
    display: block;
    margin: 10px 0 30px;
  }
`

const Instructions = styled.p`
  font-weight: 600;
  margin-bottom: 30px;
  color: ${colors.grey};
`

const LegalDisclaimer = styled.p`
  padding: 20px;
  border-radius: 5px;
  background-color: ${colors.lightGrey};
  margin-top: 30px;
`

const Template = styled.p`
  margin-left: 20px;
`

export const Welcome = () => {
  const { name } = useContext(PracticeContext)
  const { setStage, templates } = useContext(DocumentFlowContext)

  useEffect(() => {
    document.title = `Gesundheitsfragen ${name}`
  }, [name])

  return (
    <Wrapper>
      <Card>
        <Grid item>
          <Grid item xs={12}>
            <Titles>
              <Typography variant="h1" data-testid="welcome-title">
                <FormattedMessage id="welcome.title" />
              </Typography>
              <PracticName>
                <Typography variant="h2">{name}</Typography>
              </PracticName>
            </Titles>
          </Grid>
          <LanguagePickerRow />
          <p>
            <FormattedMessage id="welcome.introParagraph" />
          </p>
          {templates ? (
            <>
              <Grid item xs={12}>
                <Instructions>
                  <FormattedMessage id="welcome.instructions" />
                </Instructions>
                {templates.map((t, index) => (
                  <Template key={t.title}>
                    {index + 1}. {t.title}
                  </Template>
                ))}
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Instructions>
                <FormattedMessage id="welcome.noDocuments" />
              </Instructions>
            </Grid>
          )}
        </Grid>
        <LegalDisclaimer>
          <FormattedMessage
            id="welcome.legalDisclaimer"
            values={{
              privacyPolicy: (
                <Link target="_blank" href={'/datenschutz'}>
                  <FormattedMessage id="footer.privacyPolicy" />
                </Link>
              ),
            }}
          />
        </LegalDisclaimer>
      </Card>
      {templates && (
        <ButtonWrapper>
          <ActionButton
            title={<FormattedMessage id="welcome.startButton" />}
            action={() => setStage('documentFlow')}
            primary
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}
