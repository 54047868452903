import React from 'react'
import styled from 'styled-components'
import { footerHeight } from './Footer'
import { headerHeight } from './Header'

const Container = styled.div`
  margin-top: ${headerHeight}px;
  min-height: calc(100vh - ${footerHeight}px - ${headerHeight}px);
`

export const AppContent = (props: any) => (
  <Container key={props.children}>{props.children}</Container>
)
