import React, { useContext } from 'react'
import { Card } from '../components/Card'
import { DocumentFlowContext , DocumentFlowContextexisting } from '../contexts/DocumentFlowContext'
import { FormConsent } from '../components/FormConsent'
import { FormContent } from '../components/FormContent'
import { useIntl } from 'react-intl'

export const DocumentTemplate = () => {
  const { locale } = useIntl()
  const { templates, stepIndex } = useContext(DocumentFlowContext)
  const template = templates[stepIndex - 1]
  const templateId = (stepIndex - 1).toString()
  return (
    <>
   
      <Card>
      {
        //@ts-ignore
      template!==undefined?
      <FormContent
      atn={template.atn}
      templateId={templateId}
      activeLang={locale}
    />:""
    }
        
      </Card>
      {/* <Card>
        <FormConsent templateId={templateId} activeLang={locale} />
      </Card> */}
    </>
  )
}
export const DocumentTemplateExisting = () => {
  const { locale } = useIntl()
  const { templates, stepIndex } = useContext(DocumentFlowContextexisting)
  // const template = templates[stepIndex]
  const template = templates[stepIndex]

  const templateId = (stepIndex).toString()
  return (
    <>
    { template!==undefined?
      <Card>
     
       
     
      <FormContent
      atn={template.atn}
      templateId={templateId}
      activeLang={locale}
    />
    
        
      </Card>:""}
    
    </>
  )
}
