import React, { useContext, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { AnamnesisSubmission } from '../../types/AnamnesisSubmission'
import { Container, Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { FormError } from '../../components/FormError'
import { FormFocusError } from '../../components/FormFocusError'
import { getFixedStepsNew } from './fixedSteps'
import { Loading } from '../Loading'
import { StepButtons } from '../../components/StepButtons'
import { StepContent } from '../../components/StepContentNew'
import { Stepper } from '../../components/Stepper'
import { submissionServer, redirecttoServer } from '../../config'
import { useIntl } from 'react-intl'
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'

import {
  DocumentFlowContext,
  getSteps,
} from '../../contexts/DocumentFlowContext'
import {
  assembleSubmission,
  generateInitialFormValues,
  generateInitialConsentValues,
  generateInitialFixedStepValues,
} from '../../utils/formHelpers'
import { object, string } from 'yup'

const axios = require('axios');
interface Props {
  extraSubmissionData: { [key in string]?: {} }
}

export const DocumentFlow = ({ extraSubmissionData }: Props) => {
  const history = useHistory();
  const { ip } = useParams<any>();

  const { formatMessage } = useIntl()
  const { templates, setStage, stepIndex, setStepIndex } = useContext(
    DocumentFlowContext,
  )

  const fixedSteps = getFixedStepsNew(formatMessage)
  const steps = getSteps(fixedSteps, templates)
  let initialPatientValues = generateInitialFixedStepValues(fixedSteps[0])

  const [{ loading, error }, submitForm] = useAxios<AnamnesisSubmission>(
    {
      url: `https://${ip}${submissionServer}`,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    },
    {
      manual: true,
    },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  if (loading) return <Loading />

  return (
    <>
      <Formik
        initialValues={{

          ...initialPatientValues,
          ...generateInitialFormValues(templates),
          ...generateInitialConsentValues(templates),
        }}
        validationSchema={steps[0].validationSchema}
        onSubmit={(values, formikHelpers) => {
          if (stepIndex < steps.length - 1) {
            setStepIndex(stepIndex + 1)
            formikHelpers.setTouched({})
            formikHelpers.setSubmitting(false)
          } else {
            const data = {
              ...assembleSubmission(values as any, templates),
              ...extraSubmissionData,
              ip:ip
            }
            axios({
              method: 'Post',
              url: 'https://' + ip + submissionServer,
              data: data,
            }).then((result: any) => {
              if(result.data.ptid!==0 || result.data.ptid!=="0")
              {
                if(result.data.navigateto==="patientlist")
                {
                  window.location.assign(redirecttoServer+"patientlist");
                }
                else if(result.data.navigateto==="Detailpatients")
                {
                  window.location.assign(redirecttoServer+"itemDetails/"+result.data.ptid);
                }
              }
              setStage('thankYou')
            });

            // submitForm({ data }).then(() => {
            //   setStage('thankYou')
            // })
          }
        }}>
        <Form>
          <Container maxWidth="lg">
            <Stepper steps={steps} />
          </Container>
          {error && <FormError />}
          <Grid container direction="column">
            <StepContent stepIndex={stepIndex} steps={steps} />
          </Grid>
          <Container maxWidth="md">
            <StepButtons
              stepIndex={stepIndex}
              steps={steps}
              setStepIndex={setStepIndex}
            />
          </Container>
          <FormFocusError />
        </Form>
      </Formik>

    </>
  )
}
