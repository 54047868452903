import React,{useEffect , useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import GestureIcon from '@material-ui/icons/Gesture';
import DrawOnImage from "./DrawOnImage";
import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Create';
import SignPadPopUp from "../pages/signaturePadPopup";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));



const Sign = {
  width: "300px",
  margin: "35px auto",
  paddingBottom: "10px",
}
const SignField = {
  cursor: "pointer",
  border: "4px dashed #999",
  padding: "20px",
  textAlign: "left",
  height: "200px",
  textAlign: "center"
}
const SignatureCaption = {
  fontStyle: "italic",
  fontSize: "18px",
  margin: "10px 20px",
}
const SignTitle = {
  fontSize: "20px",
  marginBottom: "10px",
}
const SignInfoLabel ={
  marginTop: "10px",
  fonSize: "16px",
  color: "#666",
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SavedSignature(props) {
  const classes = useStyles();
  const [imgurl, setimgurl] = React.useState("/graph.jpg");
  const [open, setOpen] = React.useState(false);
  const [opensignpad, setopensignpad] = React.useState(false);
  const [currenttabid, setcurrenttabid] = React.useState(false);
  const [base1, setbase1] = React.useState("");
  const [base2, setbase2] = React.useState("");
  const [base3, setbase3] = React.useState("");
  const img1 = useRef(null);
  const img2 = useRef(null);
  const img3 = useRef(null);
  



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const OpenSignPad = (e) => {
    setopensignpad(true);
    setcurrenttabid(e.target.id)

  };
  const CloseSignPad = () => {
    setopensignpad(false);
  };
  const getbaseString = (base) => { 
    switch (currenttabid) {
      case "tab1":
        setbase1(base);
        break;
      case "tab2":
        setbase2(base);
        break;
      case "tab3":
        setbase3(base);
        break;
    
      default:
        break;
    }

  }
  return (
    <div>
       <IconButton color="default" aria-label="gesture" onClick={handleClickOpen} component="span">
       <CreateIcon />
       </IconButton>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Saved Signature
            </Typography>
          
          </Toolbar>
        </AppBar>
        <Grid container>
        <Grid item xs={4}>
        <div style={Sign}>
        <div style={SignField} id="tab1" onClick={OpenSignPad}>
       <p style={SignatureCaption}>SignatureCaption</p> 

      {base1 !== "" ? <img src={base1} ref={img1} id="tab1" alt="" style={{width:"100%",height: "80%"}} />: <img src="/pointer.svg" ref={img1} id="tab1" alt="" style={{width:"80px",height: "70%"}} /> } 
        </div>
       
        <p style={SignTitle}>Unterschrift OPeter Beispiel/Bevollmachtigter (*)</p> 
        {/* <p style={SignInfoLabel}>SignInfoLabel</p>  */}
      </div> 
        </Grid>
        <Grid item xs={4}>
        <div style={Sign}>
        <div style={SignField} id="tab2" onClick={OpenSignPad}>
       <p style={SignatureCaption}>SignatureCaption</p> 

       {base2 !== "" ? <img src={base2} ref={img2} id="tab2" alt="" style={{width:"100%",height: "80%"}} />: <img src="/pointer.svg" ref={img2} id="tab2" alt="" style={{width:"80px",height: "70%"}} />} 
        </div>
       
        <p style={SignTitle}>Unterschrift OPeter Beispiel/Bevollmachtigter (*)</p> 
        {/* <p style={SignInfoLabel}>SignInfoLabel</p>  */}
      </div> 
        </Grid>
        <Grid item xs={4}>
        <div style={Sign}>
        <div style={SignField}  id="tab3" onClick={OpenSignPad}>
       <p style={SignatureCaption}>SignatureCaption</p> 

       {base3 !== "" ?  <img src={base3} ref={img3} id="tab3" alt="" style={{width:"100%",height: "80%"}} />:<img src="/pointer.svg" ref={img3} id="tab3" alt="" style={{width:"80px",height: "70%"}} />} 
        </div>
       
        <p style={SignTitle}>Unterschrift OPeter Beispiel/Bevollmachtigter (*)</p> 
        {/* <p style={SignInfoLabel}>SignInfoLabel</p>  */}
      </div> 
        </Grid>
        </Grid>
     
     
     
      </Dialog>
                    <SignPadPopUp baseString={getbaseString} open={opensignpad} close={CloseSignPad}/>

    </div>
  );
}
