import React from 'react'
import { DocumentTemplateExisting } from '../pages/DocumentTemplate'
import { PatientInfo } from '../pages/PatientInfoNew'
import { StepInterface } from '../types/Step'
import { SummaryExisting } from '../pages/SummaryExisting'

interface StepContentProps {
  stepIndex: number
  steps: StepInterface[]
}

export const StepContent = (props: StepContentProps) => {
  const lastIndex = props.steps.length -2

  switch (props.stepIndex) {
    // case 0:
    //     return  <PatientInfo />
    case lastIndex:
      return <SummaryExisting />
    default:
      return <DocumentTemplateExisting />
  }
}
