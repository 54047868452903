import MomentUtils from '@date-io/moment'
import { MuiThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/de'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from "./app"
import { DocumentFlowProvider } from './contexts/DocumentFlowProvider'
import { DocumentFlowProviderexist } from './contexts/DocumentFlowProviderExisting'
import { InvitationDetailsProvider } from './contexts/InvitationDetailsProvider'
import { LanguageProvider } from './contexts/LanguageProvider'
import { PatientProvide } from './contexts/PatientProvide'
import { PracticeProvider } from './contexts/PracticeProvider'
import * as serviceWorker from './serviceWorker'
import { GlobalStyle, theme } from './utils/theme'
const config = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY || '',
  environment: process.env.NODE_ENV,
}




ReactDOM.render(
    <LanguageProvider>
      <PatientProvide>
        <PracticeProvider>
          <DocumentFlowProvider>
            <DocumentFlowProviderexist>
              <InvitationDetailsProvider>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
                  <Router>
                    <GlobalStyle />
                    <MuiThemeProvider theme={theme}>
                      <App />
                    </MuiThemeProvider>
                  </Router>
                </MuiPickersUtilsProvider>
              </InvitationDetailsProvider>
            </DocumentFlowProviderexist>
          </DocumentFlowProvider>
        </PracticeProvider>
      </PatientProvide>
    </LanguageProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
